
  import { Component, Mixins } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Resource, ResourceType, TextValuePair, User } from '@/interfaces';
  import UsersList from '@/mixins/usersList';

  const resourcesStore = namespace('resources');

  @Component
  export default class UsersPage extends Mixins(UsersList) {
    get headers(): TextValuePair[] {
      return [
        { value: 'lastname', text: this.$t('users.lastname') as string },
        { value: 'firstname', text: this.$t('users.firstname') as string },
        { value: 'email', text: this.$t('users.email') as string },
        { value: 'role', text: this.$t('users.role') as string },
        { value: 'limitations', text: this.$t('users.limitations') as string },
        { value: 'ops', text: '' },
      ]
    }

    @resourcesStore.Getter('resosurcesMap')
    resources!: () => Record<number, Resource>;

    mounted(): void {
      this.getResources();
    }

    @resourcesStore.Action
    getResources!: (type?: ResourceType) => Promise<void>;

    getResourceName(user: User, trim = false): string {
      let res = '';
      switch (user.role) {
        case 'country':
          res = user.countries?.join(', ') || '';
        break;

        case 'regional':
        case 'brand':
          res = user.resources
            ?.map(item => this.resources[item.resourceId]?.name)
            .join(', ') || ''
        break;

        default:
          res = '-';
        break
      }

      const limit = 30;
      return trim && res.length > limit
        ? res.substring(0, limit) + '...'
        : res;
    }
  }
